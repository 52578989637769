import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy"
import "../../../static/index.scss"

class HeaderFour extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
       //  window.addEventListener('load', function() {
       //      console.log('All assets are loaded')
       //  })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){

        if (typeof window !== `undefined`) {
            var elements = document.querySelectorAll('.has-droupdown > a');
            for (var i in elements) {
                if (elements.hasOwnProperty(i)) {
                    elements[i].onclick = function() {
                        this.parentElement.querySelector('.submenu').classList.toggle("active");
                        this.classList.toggle("open");
                    }
                }
            }
        }


        return(
          <header className="header-area formobile-menu header--fixed default-color">
              <div className="header-wrapper" id="header-wrapper">
                  <div className="header-left">
                      <div className="logo">
                          <a href="/">
                              <img className="logo-1" src="/assets/images/logo/logo-light.svg" width="300" alt="EdelNetzwerk Logo"/>
                              <img className="logo-2" src="/assets/images/logo/logo-all-dark.svg" width="300" alt="EdelNetzwerk Logo"/>
                          </a>
                      </div>
                  </div>
                  <div className="header-right">
                      <nav className="mainmenunav d-lg-block">
                          <Scrollspy className="mainmenu" items={['home','portfolio', 'about', 'imprint']} currentClassName="is-current" offset={-200}>
                              <li><a href="/#home">Home</a></li>
                              <li><a href="/#portfolio">Portfolio</a></li>
                              <li><a href="/#about">Über Uns</a></li>
                              <li><a href="/#imprint">Impressum</a></li>
                          </Scrollspy>
                      </nav>
                      {/* Start Humberger Menu  */}
                      <div className="humberger-menu d-block d-lg-none pl--20">
                          <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                      </div>
                      {/* End Humberger Menu  */}
                      <div className="close-menu d-block d-lg-none">
                          <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                      </div>
                  </div>
              </div>
          </header>
        )
    }
}
export default HeaderFour;
